// https://vuejsexamples.com/simple-alert-for-vue-js/
//IMPORTANTE AL DESPLEGAR EL API WEB.CONFIG CAMBIA EL VALOR hostingModel="OutOfProcess" A hostingModel="inprocess", debe estar en hostingModel="OutOfProcess" para poder funcionar

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);
Vue.config.productionTip = true;

Vue.prototype.$hostname = Vue.config.productionTip
  ? "https://juanabapl.juanabonita.com.co:446/GestionCasosNetcore"
  : "http://192.168.1.135:45455";

new Vue({
  router,
  store,
  render: (h) => h(App), 
}).$mount("#app");
